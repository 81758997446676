// keep filtering parameters in localStorage and if there are none, redirect to filters
import {
  Container,
  Typography,
  Button,
  Box,
  Divider,
  TextField,
} from "@material-ui/core"
import React, { useState, useEffect } from "react"
import swal from "sweetalert"
import { navigate } from "gatsby"
import axios from "axios"
import Dots from "../components/Dots"
import styled from "styled-components"
import MetaHeader from "../components/MetaHeader"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import { SvgIcon } from "@material-ui/core"
import _c from "../uiConfig/colors"
import BigScreenMapImage from "../components/BigScreenMapImage"
import validateEmail from "../utils/validateEmail"

const DownloadScreen = ({ location }) => {
  const dropdownOpen = useState(false)
  // const troubleSendingEmailState = useState(false)
  const xls_link = useState("#")
  const pdf_link = useState("#")

  const email = useState("")
  const emailError = useState(false)

  const email2 = useState("")
  const email2Error = useState(false)
  const emailDontMarchError = useState(false)

  const createEmailError = (invalidEmail, dontMatch) => {
    if (invalidEmail) {
      return "Invlid email."
    }
    if (dontMatch) {
      return "Emails don't match."
    }
  }

  useEffect(() => {
    if (location.state) {
      const { xls_url, pdf_url } = location.state
      if (!xls_url || !pdf_url) {
        navigate("/")
      } else {
        xls_link[1](xls_url)
        pdf_link[1](pdf_url)
        // troubleSendingEmailState[1](troubleSendingEmail)
      }
    } else {
      navigate("/")
    }
  })

  const resendEmail = () => {
    axios({
      method: "POST",
      url:
        "https://us-central1-vending-machine-app-9eb8c.cloudfunctions.net/resendVendingLocationExportedList",

      // "http://localhost:5001/vending-machine-app-9eb8c/us-central1/resendVendingLocationExportedList",
      data: { xls_url: xls_link[0], pdf_url: pdf_link[0], email: email[0] },
    })
      .then(res => {
        console.log(res)
        swal(
          "Success!",
          `Sent to ${email[0]}.\nPlease check your email.`,
          "success"
        )
      })
      .catch(error => console.log(error))
  }
  const focusNextInput = (e, id) => {
    e.preventDefault()
    if (window && document) {
      if (e.key === "Enter" || e.key === "Return") {
        document.getElementById(id).focus()
      }
      // if (e.keyCode === 13)
    }
  }
  return (
    <MetaHeader>
      <StyledContainer>
        <Dots dotsArrLength={3} selectedDotInd={2} />
        <Typography
          style={{ fontFamily: "Anton" }}
          variant="h4"
          component="h1"
          color={"primary"}
          gutterBottom
        >
          Download
        </Typography>
        <Box
          component={"main"}
          pt={"5%"}
          style={{ flex: 1, flexDirection: "column" }}
        >
          <StyledTypography
            variant="subtitle1"
            component="p"
            color={"secondary"}
            gutterBottom
          >
            <StyledBox component="span">{"Thanks "}</StyledBox>
            for your order!
          </StyledTypography>
          <InfoStyledTypography
            variant="subtitle1"
            component="p"
            color={"secondary"}
            gutterBottom
          >
            You can now
            <StyledBox component="span">
              {" download your generated list "}
            </StyledBox>
          </InfoStyledTypography>
          <InfoStyledTypography
            variant="subtitle1"
            component="p"
            color={"secondary"}
            gutterBottom
          >
            Links to your files were also sent to your email. If you didn't get
            them, please re-enter your email here
          </InfoStyledTypography>
          <FilesIcon src={"/files_icon.png"} alt="files_icon" />
          <BtnContainer>
            {/* {troubleSendingEmailState[0] && ( */}
            <Box style={{ flex: 1 }} component={"div"}>
              <TF
                id={"email"}
                onKeyUp={e => focusNextInput(e, "confirm-email")}
                inputProps={{
                  autoCapitalize: "off",
                  autoComplete: "off",
                }}
                error={emailError[0] || emailDontMarchError[0]}
                value={email[0]}
                onChange={e => email[1](e.target.value)}
                onBlur={() => {
                  emailDontMarchError[1](
                    email[0] !== "" &&
                      email2[0] !== "" &&
                      email2[0] !== email[0]
                  )
                  emailError[1](!validateEmail(email[0]) && email[0] !== "")
                }}
                label="Email*"
                helperText={createEmailError(
                  emailError[0],
                  emailDontMarchError[0]
                )}
              />
              <div
                style={{
                  backgroundColor: "transparent",
                  width: "100%",
                  height: "10px",
                }}
              />
              <TF
                id={"confirm-email"}
                onKeyUp={e => focusNextInput(e, "email")}
                inputProps={{
                  autoCapitalize: "off",
                  autoComplete: "off",
                }}
                error={emailError[0] || emailDontMarchError[0]}
                value={email2[0]}
                onChange={e => email2[1](e.target.value)}
                onBlur={() => {
                  emailDontMarchError[1](email2[0] !== email[0])
                  emailDontMarchError[1](
                    email[0] !== "" &&
                      email2[0] !== "" &&
                      email2[0] !== email[0]
                  )
                  email2Error[1](!validateEmail(email2[0]) && email2[0] !== "")
                }}
                label="Confirm Email*"
                helperText={createEmailError(
                  email2Error[0],
                  emailDontMarchError[0]
                )}
              />
              <WhiteButton
                disabled={
                  email[0] === "" ||
                  email2[0] === "" ||
                  emailError[0] ||
                  email2Error[0] ||
                  xls_link[0] === "#" ||
                  pdf_link[0] === "#" ||
                  emailDontMarchError[0]
                }
                onClick={resendEmail}
                variant="outlined"
              >
                Send Email
              </WhiteButton>
            </Box>
            {/* )} */}
            <IconButtonContainer
              style={{
                backgroundColor: dropdownOpen[0] ? _c.white : "transparent",
              }}
            >
              <StyledButton
                onClick={() => dropdownOpen[1](!dropdownOpen[0])}
                startIcon={
                  <SvgIcon style={{ marginTop: "30%" }}>
                    <g clipPath="url(#clip0)">
                      <path
                        d="M8 11.3334C7.724 11.3334 7.5 11.1094 7.5 10.8334V0.5C7.5 0.223999 7.724 0 8 0C8.276 0 8.5 0.223999 8.5 0.5V10.8334C8.5 11.1094 8.276 11.3334 8 11.3334Z"
                        fill="white"
                      />
                      <path
                        d="M8 11.3334C7.87195 11.3334 7.74402 11.2847 7.64661 11.1866L4.97998 8.52002C4.78467 8.32471 4.78467 8.00806 4.97998 7.81262C5.17529 7.61731 5.49194 7.61731 5.68738 7.81262L8.00061 10.126L10.314 7.81262C10.5093 7.61731 10.826 7.61731 11.0214 7.81262C11.2167 8.00806 11.2167 8.32471 11.0214 8.52002L8.35461 11.1866C8.25598 11.2847 8.12805 11.3334 8 11.3334Z"
                        fill="white"
                      />
                      <path
                        d="M14.1666 16H1.83337C0.822021 16 0 15.178 0 14.1666V11.1666C0 10.8906 0.223999 10.6666 0.5 10.6666C0.776001 10.6666 1 10.8906 1 11.1666V14.1666C1 14.626 1.37402 15 1.83337 15H14.1666C14.626 15 15 14.626 15 14.1666V11.1666C15 10.8906 15.224 10.6666 15.5 10.6666C15.776 10.6666 16 10.8906 16 11.1666V14.1666C16 15.178 15.178 16 14.1666 16Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </SvgIcon>
                }
                variant={"contained"}
                color={"primary"}
              >
                Download
              </StyledButton>
              <TriangleIconContainer componet={"div"}>
                {renderRightButtonIcon(dropdownOpen[0])}
              </TriangleIconContainer>
            </IconButtonContainer>
            {dropdownOpen[0] && (
              <Dropdown component={"div"}>
                <DropdownButton target={"_blank"} href={xls_link[0]}>
                  .xlsx
                </DropdownButton>
                <Divider style={{ maxWidth: "90%", margin: "0 auto" }} />
                <DropdownButton target={"_blank"} href={pdf_link[0]}>
                  .pdf
                </DropdownButton>
              </Dropdown>
            )}
          </BtnContainer>
        </Box>
      </StyledContainer>
      <BigScreenMapImage />
    </MetaHeader>
  )
}
const renderRightButtonIcon = bool =>
  bool ? (
    <ArrowDropUpIcon style={{ color: "#bb0" }} />
  ) : (
    <ArrowDropDownIcon style={{ color: "#bb0" }} />
  )

const StyledContainer = styled(Container)`
  overflow: auto;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 5%;
  @media screen and (orientation: landscape) and (max-height: 600px) {
    padding-bottom: 120px;
  }

  /* @media screen and (min-width: 650px) { */
  @media screen and (min-width: 900px) {
    padding: 5% 15%;
  }
`
const StyledTypography = styled(Typography)`
  font-family: Work Sans;
  font-weight: 200;
  margin-bottom: 10px;
`

const InfoStyledTypography = styled(Typography)`
  font-family: Work Sans;
  font-weight: 200;
  margin-bottom: 10px;
  @media screen and (min-width: 650px) {
    padding-right: 30%;
  }
`
const FilesIcon = styled.img`
  height: 50px;
  width: 50px;
  align-self: flex-start;
  margin-top: 5%;
`
const StyledBox = styled(Box)`
  font-weight: 300;
  font-family: Work Sans;
`
const Dropdown = styled(Box)`
  background-color: ${_c.white};
  height: auto;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`
const IconButtonContainer = styled(Box)`
  height: auto;
  width: auto;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`
const StyledButton = styled(Button)`
  background-color: #ffbf00;
  &:hover {
    background-color: #eeae00;
  }
  color: #fff;
  padding: 7px 14px;
  max-height: 38px;
  border-radius: 5px;
  min-width: 100%;
  text-transform: none;
  font-family: Work Sans;
  font-weight: 300;
`
const TF = styled(TextField)`
  font-family: Work Sans !important;
  font-weight: 200;
  width: 100%;
`
const WhiteButton = styled(Button)`
  /* padding: 7px 14px; */
  border-radius: 5px;
  min-width: 100%;
  height: 38px;
  text-transform: none;
  font-family: Work Sans;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 300;
`
const DropdownButton = styled(Button)`
  padding: 7px 14px;
  min-width: 100%;
  text-transform: none;
  font-family: Work Sans;
  font-weight: 300;
`
const BtnContainer = styled.div`
  flex: 1;
  display: flex;
  /* padding-top: 10%; */
  max-height: 20% !important;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (min-width: 650px) {
    padding-right: 35%;
    padding-top: 5%;
  }
`
const TriangleIconContainer = styled(Box)`
  background-color: rgba(205, 141, 0, 0.5);
  opacity: 0.8;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  height: 100%;
  width: 12%;
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`
export default DownloadScreen
